<template>
  <div id="lang" @click="ChangeLang">
    {{ $store.state.lang }}
  </div>
</template>

<script>
export default {
  methods: {
    ChangeLang () {
      let text = document.getElementById('lang').innerText
      if (text == 'CN') {
        this.$store.commit('setLang', 'EN')
        this.$i18n.locale = 'en'
      } else {
        this.$store.commit('setLang', 'CN')
        this.$i18n.locale = 'cn'
      }
    }
  }
}
</script>

<style lang="less" scoped>
#lang {
  width: 61px;
  height: 27px;
  color: #fff;
  font-size: 18px;
  border: 1px solid #fff;
  border-radius: 10px;
  text-align: center;
  margin-left: 20px;
}
</style>
