<template>
  <div class="Contact_us">
    <van-popup
      v-model="this.$store.state.show"
      position="right"
      :style="{ height: '100%', width: '223px' }"
      class="genduomenu"
    >
      <el-input
        suffix-icon="el-icon-search"
        class="PeSearch"
        v-model="searchText"
      >
      </el-input>
      <ul class="peMenu">
        <li @click="toHome">
          <span>首页</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toBusiness">
          <span>经营范围</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toNews">
          <span>新闻资讯</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toAdvantage">
          <span>核心优势</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toGroup_Introduction">
          <span>集团介绍</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toContact_us">
          <span>联系我们</span><i class="el-icon-arrow-right"></i>
        </li>
      </ul>
    </van-popup>
    <div id="header">
      <div class="top">
        <img src="@/assets/Home/login.png" alt="" class="logo" />
        <ul class="left">
          <li class="first" @click="toHome">
            <img src="@/assets/Home/homeIcon.png" class="homeIcon" alt="" />
            {{ $t('home.HomePage') }}
          </li>
          <li @click="toBusiness">{{ $t('home.businessScope') }}</li>
          <li @click="toNews">{{ $t('home.News') }}</li>
          <li @click="toAdvantages">{{ $t('home.Advantage') }}</li>
          <li @click="toGroup_Introduction">{{ $t('home.Introduce') }}</li>
          <li @click="toContact_us">{{ $t('home.ContactUs') }}</li>
        </ul>
        <div class="right">
          <el-input suffix-icon="el-icon-search" v-model="searchText">
          </el-input>
          <Lang></Lang>
        </div>
        <img
          class="rightPe"
          @click.stop="showMenu"
          src="@/assets/PE/Home/436.png"
          alt=""
        />
      </div>
    </div>
    <div class="layer1" @click.stop="popHide">
      <div class="left"></div>
      <div class="right">
        <h2>{{ $t('Contact_us.CONTACT_US') }}</h2>
        <p>
          {{ $t('Contact_us.Introduce') }}
        </p>
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item :label="this.$t('Contact_us.TheMessageHeader')">
            <el-input></el-input>
          </el-form-item>
          <el-form-item :label="this.$t('Contact_us.Content')">
            <el-input type="textarea" rows="10"></el-input>
          </el-form-item>

          <el-form-item :label="this.$t('Contact_us.Contact')">
            <el-input></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="btn" size="medium">{{
              $t('Contact_us.Submit')
            }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import footers from '../../components/footer.vue'
import Lang from '../../components/Lang.vue'
export default {
  components: {
    footers,
    Lang
  },
  data () {
    return {
      form: {},
      searchText: ''
    }
  },
  created () {
    this.$store.commit('setShow', false)
  },
  methods: {
    toHome () {
      this.$router.push('/Home')
    },
    toBusiness () {
      this.$router.push('/Business')
    },
    toNews () {
      this.$router.push('/News')
    },
    toAdvantages () {
      this.$router.push('/Advantage')
    },
    toAdvantage () {
      this.$router.push('/Advantage')
    },
    toContact_us () {
      this.$router.push('/Contact_us')
    },
    toGroup_Introduction () {
      this.$router.push('/Group_Introduction')
    },
    showMenu () {
      this.$store.commit('setShow', true)
      console.log(this.$store.state.show)
    },
    popHide () {
      this.$store.commit('setShow', false)
    }
  }
}
</script>

<style lang="less" scoped>
.Contact_us {
  width: 100%;
  #header {
    width: 100%;
    height: 100px;
    background: #333333;
    background-size: cover;
    position: relative;
    .top {
      width: 1400px;
      height: 100px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      .logo {
        width: 95px;
        height: 27px;
        margin-right: 30px;
      }
      .left {
        width: 1000px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
          color: #fff;
          list-style: none;
          display: block;
          cursor: pointer;
          font-size: 16px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          .homeIcon {
            margin-right: 10px;
          }
        }
        .first {
          border-left: 1px solid #fff;
          padding-left: 21px;
          display: flex;
          align-items: center;
        }
      }
      .right {
        margin-left: 145px;
        width: 280px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /deep/ .el-input {
          width: 206px;
          height: 36px;
          .el-input__inner {
            background-color: rgba(255, 255, 255, 0.3);
            -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
            border-radius: 25px;
            border: 0;
            color: #fff;
          }
        }
      }
      .rightPe {
        display: none !important;
      }
    }
  }
  .layer1 {
    margin: 0 auto;
    width: 1640px;
    height: 950px;
    opacity: 1;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
    margin-top: 90px;
    display: flex;
    .left {
      width: 666px;
      height: 100%;
      background: url('../../assets/Contact_us/478.png');
    }
    .right {
      width: 974px;
      height: 100%;
      padding: 60px 90px;
      h2 {
        font-size: 40px;
        font-family: Didot, Didot-Bold;
        font-weight: 700;
        text-align: left;
        color: #333333;
        line-height: 60px;
      }
      p {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
  }
  .footer {
    background: url('../../assets/Home/bottom.png');
    background-size: cover;
    height: 470px;
    margin-top: 90px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .top {
      width: 1400px;
      height: 40px;
      margin: 0 auto;
      padding-top: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        height: 41px;
      }
      .right {
        display: flex;
        justify-content: space-between;
        li {
          margin-left: 100px;
          display: flex;
          align-items: center;
        }
      }
    }
    .bottom {
      width: 1400px;
      height: 32px;
      margin: 0 auto;
      margin-top: 92px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .first {
        display: flex;
        align-items: center;
        .left {
          margin-right: 10px;
        }
      }
      .last {
        display: flex;
        align-items: center;
        * {
          margin-left: 30px;
        }
        span {
          font-size: 20px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
        }
      }
    }
  }
}

.el-button--medium {
  width: 100% !important;
  height: 64px !important;
  background: #4898ff !important;
  border-radius: 5px !important;
}
@media screen and (max-width: 767px) {
  .Contact_us {
    width: 100%;
    #header {
      width: 100%;
      height: 60px;
      background: #333333;
      background-size: cover;
      position: relative;
      .top {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        margin: 0 auto;
        .logo {
          width: 95px;
          height: 27px;
          margin-right: 30px;
          margin-left: 10px;
        }
        .left {
          display: none !important;
          width: 1000px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          li {
            color: #fff;
            list-style: none;
            display: block;
            cursor: pointer;
            font-size: 16px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            .homeIcon {
              margin-right: 10px;
            }
          }
          .first {
            border-left: 1px solid #fff;
            padding-left: 21px;
            display: flex;
            align-items: center;
          }
        }
        .right {
          display: none !important;
          margin-left: 145px;
          width: 280px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          /deep/ .el-input {
            width: 206px;
            height: 36px;
            .el-input__inner {
              background-color: rgba(255, 255, 255, 0.3);
              -webkit-backdrop-filter: blur(2px);
              backdrop-filter: blur(2px);
              border-radius: 25px;
              border: 0;
              color: #fff;
            }
          }
        }
        .rightPe {
          display: block !important;
          width: 21px;
          height: 16.5px;
          position: absolute;
          right: 25px;
          top: 20.5px;
        }
      }
    }
    .layer1 {
      margin: 0 auto;
      width: 316px;
      height: 100%;
      opacity: 1;
      background: #ffffff;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
      margin-top: 33px;
      display: flex;
      flex-wrap: wrap;
      .left {
        width: 316px;
        height: 377px;
        background: url('../../assets/PE/Contact_us/469.png') no-repeat;
        background-size: cover;
      }
      .right {
        width: 316px;
        height: 100%;
        padding: 0;
        padding-left: 25px;
        padding-right: 25px;
        h2 {
          font-size: 15px;
          font-family: Didot, Didot-Bold;
          font-weight: 700;
          text-align: left;
          color: #333333;
          line-height: 30px;
        }
        p {
          margin-top: 12px;
          margin-bottom: 30px;
        }
        .btn {
          height: 32px !important;
        }
      }
    }
    .footer {
      background: url('../../assets/Home/bottom.png');
      background-size: cover;
      height: 470px;
      margin-top: 90px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .top {
        width: 1400px;
        height: 40px;
        margin: 0 auto;
        padding-top: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          height: 41px;
        }
        .right {
          display: flex;
          justify-content: space-between;
          li {
            margin-left: 100px;
            display: flex;
            align-items: center;
          }
        }
      }
      .bottom {
        width: 1400px;
        height: 32px;
        margin: 0 auto;
        margin-top: 92px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .first {
          display: flex;
          align-items: center;
          .left {
            margin-right: 10px;
          }
        }
        .last {
          display: flex;
          align-items: center;
          * {
            margin-left: 30px;
          }
          span {
            font-size: 20px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #333333;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.genduomenu {
  width: 223px;
  background-color: #3333336a !important;
  backdrop-filter: blur(8px);
  // border-radius: 40px;
  position: absolute;
  right: 0;
  top: 0;
  .PeSearch {
    width: 188px;
    height: 30px;
    border-radius: 40px;
    margin-left: 20px;
    margin-right: 15px;
    margin-top: 20px;
    margin-bottom: 30px;
    color: #fff;
    .el-input__inner {
      background-color: rgba(255, 255, 255, 0.226) !important;
      color: #fff;
      border-radius: 40px;
    }
  }
  .peMenu {
    width: 100%;
    li {
      width: 100%;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #fff;
      font-size: 12px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      padding: 0 15px 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
    }
  }
}
</style>
