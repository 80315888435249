<template>
  <div class="footer">
    <div class="top">
      <img class="left" src="@/assets/Home/bottomLogo.png" alt="" />
      <ul class="right">
        <li @click="toHome">{{ $t('home.HomePage') }}</li>
        <li @click="toBusiness">{{ $t('home.businessScope') }}</li>
        <li @click="toNews">{{ $t('home.News') }}</li>
        <li @click="toAdvantage">{{ $t('home.Advantage') }}</li>
        <li @click="toGroup_Introduction">{{ $t('home.Introduce') }}</li>
        <li @click="toContact_us">{{ $t('home.ContactUs') }}</li>
      </ul>
    </div>
    <div class="bottom">
      <div class="first">
        <img class="left" src="@/assets/Home/路径 178.png" alt="" />
        <div class="right">
          <p>{{ $t('home.ServiceHotline') }}</p>
          <p>86 755 23000335</p>
        </div>
        <div class="right">
          <p>{{$t('home.CompanyPhone')}}</p>
          <p>27888537</p>
          <p>27888536</p>
          <p>27888535</p>
        </div>
        <!-- <div class="m-right">
          <p>{{ $t('home.ServiceHotline') }}</p>
          <p>86 755 23000335</p>
          <p>{{$t('home.CompanyPhone')}}</p>
          <p>27888537</p>
          <p>27888536</p>
          <p>27888535</p>
        </div> -->
      </div>
      <div class="last">
        <span>{{ $t('home.FocusOnHuanyuOptoelectronics') }}</span>
        <div class="qq">
          <img src="@/assets/Home/415.png" alt="" /><br />
          <img class="PeHide" src="../assets/QQ.png" alt="" />
        </div>
        <div class="wechar">
          <img src="@/assets/Home/416.png" alt="" /><br />
          <img class="PeHide" src="../assets/we chat.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toHome () {
      this.$router.push('/Home')
    },
    toBusiness () {
      this.$router.push('/Business')
    },
    toNews () {
      this.$router.push('/News')
    },
    toContact_us () {
      this.$router.push('/Contact_us')
    },
    toAdvantage () {
      this.$router.push('/Advantage')
    },
    toGroup_Introduction () {
      this.$router.push('/Group_Introduction')
    }
  }
}
</script>

<style lang="less" scoped>
.footer {
  background: url('../assets/Home/bottom.png');
  background-size: cover;
  height: 470px;
  margin-top: 90px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .top {
    width: 1400px;
    height: 40px;
    margin: 0 auto;
    padding-top: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      height: 41px;
    }
    .right {
      display: flex;
      justify-content: space-between;
      // width: 300px;
      li {
        margin-left: 100px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  .bottom {
    width: 1400px;
    height: 32px;
    margin: 0 auto;
    margin-top: 92px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .first {
      display: flex;
      // align-items: center;
      .right{
        margin-left: 15px;
      }
      .left {
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }
    }
    .last {
      display: flex;
      align-items: center;
      * {
        margin-left: 30px;
      }
      div {
        img {
          display: block;
          margin: 0 auto;
        }
      }
      span {
        font-size: 20px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .footer {
    background: url('../assets/PE/Home/291.png');
    background-size: cover;
    height: 200px !important;
    margin-top: 20px !important;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    .top {
      width: 100%;
      height: 60px;
      margin: 0 auto;
      padding-top: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        width: 64px;
        height: 18px;
        margin-left: 15px;
      }
      .right {
        width: 220px;
        margin-right: 20px;
        margin-bottom: 0;
        margin-top: 12px;
        // display: flex;
        // justify-content: space-between;

        li {
          width: 10px;
          margin-left: 0px;
          font-size: 10px;
          // display: flex;
          // align-items: center;
          cursor: pointer;
          text-align: center !important;
        }
      }
    }
    .bottom {
      width: 100%;
      height: 80px;
      margin: 0 auto;
      margin-top: 0px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      bottom: 0px;
      font-size: 12px;
      .first {
        display: flex;
        // align-items: center;
        margin-left: 15px;
        .left {
          margin-right: 10px;
          width: 15px;
          height: 15px;
        }
        .right {
          // display: none;
          margin-left: 5px;
          p {
            margin-bottom: 0;
          }
        }
        .m-right{
          font-size: 12px;
        }
      }
      .last {
        // display: none !important;
        display: flex;
        align-items: center;
        align-items: center;
        margin-right: 10px;
        * {
          margin-left: 10px;
        }
        .qq,
        .wechar {
          display: flex;
          align-items: center;
        }
        .PeHide {
          display: none;
        }
        div {
          img {
            display: block;
            margin: 0 auto;
          }
        }
        span {
          font-size: 10px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
        }
      }
    }
  }
}
</style>
